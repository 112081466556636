import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FULL_ACCESS, OPERATOR_ACCESS } from "../../constants";
import { getAccessLevel } from "../../redux/Auth/authSelectors";
import Loader from "../Common/Loader/Loader";
import s from "./Control.module.scss";
import { Alert } from "../Common/Alerts/Alert/Alert";
import { Confirm } from "../Common/Alerts/Confirm/Confirm";
import { stopLine } from "../../redux/Line/lineActionCreators";

const Control = ({
  icon,
  text,
  name,
  active,
  stop,
  pause,
  toggleWindow,
  setPromptTitle,
  setOpenTab,
  changed,
  setShowPrompt,
  loadingControl,
  lineStatus,
  errors,
  setOperationName,
  incorrectStop,
}) => {
  const accessLevel = useSelector(getAccessLevel);
  const [showAlert, setShowAlert] = useState(false);
  const [resize, setResize] = useState(window.innerWidth < 1024);

  const onClickHandle = () => {
    switch (name) {
      case "RUN":
        setOperationName("RUN");
        break;
      case "PAUSE":
        setOperationName("PAUSE");
        break;
      case "STOP":
        setOperationName("STOP");
        break;
      case "back":
        checkChangedSettings();
        break;
      case "printing":
        setOpenTab("printing");
        break;
      case "scanning":
        setOpenTab("scanning");
        break;
      case "aggregation":
        setOpenTab("aggregation");
        break;
      case "plc":
        setOpenTab("plc");
        break;
      case "weighingScale":
        setOpenTab("weighingScale");
        break;
      case "help":
        setOpenTab("help");
        break;
      case "videoHelp":
        setOpenTab("videoHelp");
        break;
      default:
        break;
    }
  };

  const checkChangedSettings = () => {
    if (changed) {
      setPromptTitle("Настройки не сохранены. Сохранить настройки?");
      setShowPrompt(true);
    } else {
      toggleWindow(false);
    }
  };

  const isDisabled = () => {
    switch (accessLevel) {
      case FULL_ACCESS:
        return false;
      case OPERATOR_ACCESS:
        return [
          "printing",
          "aggregation",
          "scanning",
          "plc",
          "weighingScale",
        ].includes(name);
      default:
        return true;
    }
  };
  const settingsDisable =
    loadingControl ||
    (name === "settings" && ["RUN", "PAUSE"].includes(lineStatus));
  const runDisable =
    loadingControl || (name === "RUN" && ["RUN"].includes(lineStatus));
  const pauseDisable =
    loadingControl ||
    (name === "PAUSE" && ["PAUSE", "DISABLED"].includes(lineStatus));
  const stopDisable =
    loadingControl ||
    (name === "STOP" && ["STOP", "DISABLED"].includes(lineStatus));

  useEffect(() => {
    const handleResize = () => {
      setResize(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {!isDisabled() ? (
        <>
          <button
            disabled={runDisable || pauseDisable || stopDisable}
            onClick={
              name === "settings"
                ? () => toggleWindow(true, lineStatus)
                : onClickHandle
            }
            className={clsx(s.control, `${s[name.toLowerCase()]}`, {
              [s.active]: active,
              [s.error]: errors && errors.has(name),
            })}
          >
            {loadingControl === name && (
              <Loader
                mini={true}
                color={
                  name === "RUN"
                    ? "#4CAF50"
                    : name === "STOP"
                      ? "#FF5722"
                      : "#057BD6"
                }
              />
            )}
            <div className={s.controlContent}>
              {icon && <img src={icon} alt={text} className={s.controlIcon} />}
              <span className={name === 'back' && resize ? s.controlTextOff : s.controlText}>{text}</span>
            </div>
          </button>
        </>
      ) : null}
    </>
  );
};

export default Control;
