import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeWS, ws } from "./api/ws";
import { closeReleaseWS, ws_release } from "api/ws-realease";
import "./assets/styles/Main.module.scss";
import { Auth } from "./components/Auth/Auth";
import { Alert } from "./components/Common/Alerts/Alert/Alert";
import Loader from "./components/Common/Loader/Loader";
import Header from "./components/Header/Header";
import Root from "./components/Root/Root";
import {
  setAppLoading,
  setErrorText,
  setErrorTextSocket,
} from "./redux/App/appActionCreators";
import {
  getErrorText,
  getErrorTextSocket,
  getIsLoading,
} from "./redux/App/appSelectors";
import {
  checkLogin,
  login,
  setAuthErrorText,
  setIsLoggedAC,
} from "./redux/Auth/authActionCreators";
import {
  getAuthErrorText,
  getIsAuthLoading,
  getIsLogged,
} from "./redux/Auth/authSelectors";
import { useDisablePreloader } from "./hooks";
import { store } from "./redux/store";
import { getIsClient } from "./redux/Settings/BaseSettings/baseSettingsActionCreators";
import { serverStartCheck } from "./redux/Line/lineActionCreators";
import { runReleaseWS } from "./redux/App/releaseActionCreators";
import { getShouldRetryWS } from "./redux/App/appSelectors";
import AutoUpdate from "redux/App/Autoupdate/Autoupdate.index";
import { logout } from "./redux/Auth/authActionCreators";

export const handleErrorFunc = (statusCode, message) => {
  if (!statusCode) {
    store.dispatch(setErrorText(message));
  } else if (statusCode === 403) {
    if (ws) {
      closeWS();
    }
    store.dispatch(setAuthErrorText(message));
    store.dispatch(setIsLoggedAC(false));
  } else if (statusCode === 400) {
    // store.dispatch(setIsLoggedAC(false))
    store.dispatch(setErrorText(message));
  } else if (statusCode === 401) {
    console.log("before logout");
    store.dispatch(logout());
  } else {
    store.dispatch(setErrorText(message));
  }
  store.dispatch(setAppLoading(false));
};

let retryTimeout;

export const App = () => {
  const dispatch = useDispatch();
  const logged = useSelector(getIsLogged);
  const loading = useSelector(getIsLoading);
  const authLoading = useSelector(getIsAuthLoading);
  const authErrorText = useSelector(getAuthErrorText);
  const errorText = useSelector(getErrorText);
  const errorTextSocket = useSelector(getErrorTextSocket);
  const [isOpenedHelp, setIsOpenedHelp] = useState(false);
  const { View } = useDisablePreloader();
  const shouldRetryWS = useSelector(getShouldRetryWS);
  const [showAlert, setShowAlert] = useState(false);
  const [serverLoading, setServerLoading] = useState(false);
  console.log("serverLoading", serverLoading);

  useEffect(() => {
    const loadApp = async () => {
      const baseUrl = localStorage.getItem("URL");
      if (baseUrl) {
        dispatch(checkLogin());
      } else {
        const urlLoadedHandler = () => {
          dispatch(checkLogin());
        };
        window.addEventListener('urlsLoaded', urlLoadedHandler);
        return () => window.removeEventListener('urlsLoaded', urlLoadedHandler);
      }
    };

    loadApp();

    window.onbeforeunload = function () {
      return false;
    };

    return () => {
      if (ws) closeWS();
      if (ws_release) closeReleaseWS();
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(serverStartCheck());
    dispatch(runReleaseWS());
    if (shouldRetryWS) {
      clearTimeout(retryTimeout);
      retryTimeout = setInterval(() => {
        dispatch(serverStartCheck());
        dispatch(runReleaseWS());
      }, 5000);
    } else {
      clearTimeout(retryTimeout);
    }
  }, [shouldRetryWS]);

  useEffect(() => {
    const handleServerStatus = () => {
      const status = localStorage?.getItem("SERVER_STATUS") || 'DISCONNECTED';
      const shouldShowLoader = status === "DISCONNECTED" ||
        status === "CONNECTING" ||
        status === "ERROR";
      setServerLoading(shouldShowLoader);
    };

    // Начальная проверка
    handleServerStatus();

    // Проверка каждые 500мс
    const intervalId = setInterval(handleServerStatus, 500);

    // Проверка при возвращении на вкладку
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleServerStatus();
        // Перезапускаем соединение при возвращении на вкладку
        if (localStorage.getItem("SERVER_STATUS") !== 'CONNECTED') {
          dispatch(serverStartCheck());
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Проверка при фокусе окна
    const handleFocus = () => {
      handleServerStatus();
      if (localStorage.getItem("SERVER_STATUS") !== 'CONNECTED') {
        dispatch(serverStartCheck());
      }
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [dispatch]);

  // Добавим еще один эффект для обработки перезагрузки страницы
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('SERVER_STATUS', 'CONNECTING');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (logged) {
      dispatch(serverStartCheck());
      dispatch(runReleaseWS());
      dispatch(getIsClient());
    }
    console.log("logged", logged);
  }, [logged]);

  return (
    <>
      <AutoUpdate
        isLogged={logged}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
      {loading && !showAlert && <Loader />}

      {serverLoading && (
        <div style={{ display: "flex", position: "relative", zIndex: 103 }}>
          <Loader serverLoading={true} />
        </div>
      )}

      {logged ? (
        <>
          <Header setIsOpenedHelp={setIsOpenedHelp} />
          {
            < Root isOpenedHelp={isOpenedHelp} setIsOpenedHelp={setIsOpenedHelp} />
          }
          <View />
        </>
      ) : (
        <Auth
          login={login}
          errorText={authErrorText}
          isLoading={authLoading && !showAlert}
        />
      )}
      {!!errorText && !showAlert && (
        <Alert
          text={errorText}
          closeText={"Закрыть"}
          onClose={() => dispatch(setErrorText(""))}
        />
      )}
      {!!errorTextSocket &&
        errorTextSocket !== "Соединение с сервером восстановлено" && (
          <Alert
            text={errorTextSocket}
            closeText={"Ок"}
            onClose={() => {
              // dispatch(stopLine())
              dispatch(setErrorTextSocket(""));
            }}
          />
        )}
    </>
  );
};
