import clsx from 'clsx'
import React, { useEffect, useState } from 'react';
import s from './TooggleButton.module.scss'

const TooggleButton = ({ name, ref, checked, label, value, onChange, id, disabled, styles, status }) => {

    return (
        <div className={clsx(s.radio, disabled && s.disabled)} style={{ ...styles }}>
            <button
                ref={ref}
                type="button"
                name={name}
                id={id || value}
                checked={checked}
                disabled={disabled}
                onClick={onChange}
                value={value}
                className={clsx(s.toogleButton, s.radioLabel, checked ? s[value] : s[value + "Passive"])}
                style={{}}>
                <input type="radio" checked={checked}
                    value={value} className={s.radioInput} />
                {label}
            </button>
        </div>
    );
};

export default TooggleButton;
