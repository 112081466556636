import { ACCESS_LEVELS } from "./constants";

export const getDateFromTimestamp = (timestamp, options) => {
  let withoutTime = options?.withoutTime,
    delimiter = options?.delimiter || "/";

  if (!timestamp) {
    return "";
  }

  let timeZone = new Date().getTimezoneOffset() * 60 * 1000;
  let date;
  if (typeof timestamp === "number") {
    date = new Date(timestamp);
  } else {
    date = new Date(Date.parse(timestamp) + timeZone * -1);
  }

  let day = formatDate(date.getDate());
  let month = formatDate(date.getMonth() + 1);
  let year = date.getFullYear();
  let hours = formatDate(date.getHours());
  let minutes = formatDate(date.getMinutes());
  let seconds = formatDate(date.getSeconds());

  return `${day}${delimiter}${month}${delimiter}${year} ${!withoutTime ? `${hours}:${minutes}:${seconds}` : ""
    }`;
};

const formatDate = (date) => {
  return date.toString().length === 1 ? `0${date}` : date;
};

export const formatStatus = (status) => {
  switch (status) {
    case "NETWORK":
      return "Сетевая ошибка";
    case "TIMELIMIT":
      return "Время валидации истекло";
    case "NOFOUND":
      return "Не найден / Невалиден";
    case "NOREAD":
      return "Не распознан";
    case "DUPLICATE":
      return "Распознан / Дубликат";
    case "PRODUCT":
      return "Распознан / Не валиден по коду продукта";
    case "MASK":
      return "Распознан / Не валиден по маске";
    case "DEBUG":
      return "Отладка";
    case "SUPERFLUOUS":
      return "Лишний";
    case "EXPIRED":
      return "КМ просрочен";
    case "DROPOUT":
      return "Отбракован";
    case "DBNOFOUND":
      return "Не найден в БД";
    default:
      return "Распознан / Уникален";
  }
};

export const deleteDataFromLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const validateField = (name, value) => {
  if (name.includes("rejectSignalTimeLength")) {
    return Number(value) >= 0 && value.toString().match(/^\d+$/);
  }
  switch (name) {
    case "heartBeatPort":
    case "heartBeatTimeLength":
    case "heartBeatInterval":
      return value >= 0;
    case "name":
      return value.length > 0;
    case "allowedNumberErrors":
    case "numberPackagesInAggregates":
    case "timeInterval":
    case "numberPackagesPerHour":
    case "lineStopSignalTimeLength":
      return value > 0;
    case "ipAddress":
      return /^(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(value);
    case "port":
    // case "rejectPort":
    // case "rejectPort2":
    case "lineStopPort":

    case "dataBits":
    case "baudrate":
    case "address":
      return value > 0 && value.toString().match(/^\d+$/);
    case "connectionString":
      return !!value?.toString().length;
    case "serial-number":
      return !!value?.toString().length;
    case "box-number":
      return !!value?.toString().length && value.toString().match(/^\d+$/);
    default:
      return true;
  }
};

export const validate = (panel, setErrorsFunc) => {
  let newErrors = new Set();
  for (let key in panel) {
    if (!validateField(key, panel[key])) {
      newErrors.add(key);
    }
  }
  setErrorsFunc(newErrors);
};

export const addErrorControl = (name, errors, setErrors) => {
  let newErrArr = new Set(errors);
  newErrArr.add(name);
  setErrors(newErrArr);
};

export const deleteErrorControl = (name, errors, setErrors) => {
  let newErrArr = new Set(errors);
  newErrArr.delete(name);
  setErrors(newErrArr);
};

export const checkDevices = (devices) => {
  let correct = true;
  for (let device in devices) {
    for (let deviceKey in devices[device]) {
      if (!validateField(deviceKey, devices[device][deviceKey])) {
        correct = false;
        break;
      }
    }
  }
  return correct;
};

export const returnState = (state, action, stateKey, actionKey) => {
  return {
    ...state,
    [stateKey]: action[!actionKey ? stateKey : actionKey],
  };
};

export const getProductById = (productId, products) => {
  return products?.find((item) => item.id === productId)?.name.trim();
};

export const convertToUTCDate = (localDate) => {
  return Date.parse(localDate) - new Date().getTimezoneOffset() * 60 * 1000;
};

export const updateAggregationState = (
  state,
  action,
  stateKey,
  actionValue
) => {
  const arr = [...state];
  arr[action.aggregationLevel - 1] = {
    ...arr[action.aggregationLevel - 1],
    [stateKey]: action[actionValue],
  };
  return arr;
};

export const getAccessLevel = (roles) => {
  const rolesIdArr = roles.map((role) => role.id);
  return rolesIdArr.reduce((res, cur) => {
    return res | ACCESS_LEVELS[cur];
  }, 0);
};

export const lineStatusRussianEquivalents = {
  DISABLED: "Линия остановлена",
  RUN: "Линия запущена",
  PAUSE: "Линия на паузе",
};
