export let ws;

export const runLineWS = () => {
  const baseWSUrlFromLS = localStorage.getItem("WS_URL");
  const accessData = localStorage.getItem("uplineOperatorData");
  const token = accessData ? JSON.parse(accessData) : "";
  const sessionId = window.sessionStorage.getItem("X-MARK-SESSION-ID");
  const query =
    token && token.accessToken
      ? `?token=${token.accessToken}&xmarksession=${sessionId}`
      : "";
  const resultUrl = baseWSUrlFromLS || process.env.REACT_APP_WS_URL || "";

  console.log("resultUrl", resultUrl);

  ws = new WebSocket(`${resultUrl}${query}`);
  console.log("ws", ws);
};

export const closeWS = () => {
  if (ws) {
    ws.close();
  }
};
