export let ws_release;

export const startRealeaseWS = async () => {
  const baseWSUrlFromLS = localStorage.getItem("RELEASE_WS_URL");
  const accessData = localStorage.getItem("uplineOperatorData");
  const token = accessData ? JSON.parse(accessData) : "";
  const sessionId = window.sessionStorage.getItem("X-MARK-SESSION-ID");
  const query =
    token && token.accessToken
      ? `?token=${token.accessToken}&xmarksession=${sessionId}`
      : "";
  const resultUrl = baseWSUrlFromLS;
  // baseWSUrlFromLS ||
  // process.env.REACT_APP_WS_BASE_URL ||

  try {
    ws_release = new WebSocket(`${resultUrl}${query}`);
  } catch (e) {
    console.error("Error in startRealeaseWS:", e.message);
  }
};

export const closeReleaseWS = () => {
  if (ws_release) {
    ws_release.close();
  }
};
