import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDateFromTimestamp } from '../../../../../../functions'
import { getConsignment } from '../../../../../../redux/Line/lineActionCreators'
import { getSelectedConsignment, getProductionStartTime } from '../../../../../../redux/Line/lineSelectors'
import {
    getConsignmentId,
    getLineProducts,
    getSelectedProductId
} from '../../../../../../redux/Settings/Line/lineSettingsSelectors'
import { strKitcut } from '../../../../../../utils/strUtils'
import s from './ConsignmentPanel.module.scss'
import { TruncatedText } from 'components/Common/TruncatedText/TruncatedText'

export const ConsignmentPanel = ({ isConsignment }) => {
    const dispatch = useDispatch()
    const [consignment, setConsignment] = useState({})
    const [selectedProduct, setSelectedProduct] = useState(null)
    const consignmentId = useSelector(getConsignmentId)
    const selectedConsignment = useSelector(getSelectedConsignment)
    const selectedProductId = useSelector(getSelectedProductId)
    const lineProducts = useSelector(getLineProducts)
    const productionTime = useSelector(getProductionStartTime)

    useEffect(() => {
        if (Array.isArray(lineProducts)) {
            const foundProduct = lineProducts.find(item => item.id === selectedProductId);
            setSelectedProduct(foundProduct);
        }
    }, [lineProducts, selectedProductId])


    useEffect(() => {
        if (consignmentId) {
            dispatch(getConsignment(consignmentId))
            setSelectedProduct(null)
        }
    }, [consignmentId, dispatch])

    useEffect(() => {
        if (selectedConsignment) {
            const { plannedValue, product, productCreationDate, taskNumber } = selectedConsignment
            setConsignment({
                taskNumber,
                plannedValue,
                productCreationDate: getDateFromTimestamp(productCreationDate, { withoutTime: true, delimiter: '.' }),
                productName: product.name
            })
        }
    }, [selectedConsignment])




    const gtin = selectedProduct?.gtin ?? selectedConsignment?.product?.gtin ?? ''
    const productName = selectedProduct?.nameNoGtin ?? selectedConsignment?.product?.name ?? '';



    const TitleStringlimit = window.innerWidth > 1024 ? 90 : 50;

    return (
        <div className={s.consignmentPanel}>
            <div className={s.consignment}>
                {isConsignment ?

                    <div>
                        <span className={s.consignmentTitle}> {`Задание № ${consignment?.taskNumber || ""}`}</span>
                        <div className={s.row}>
                            <span className={s.key}>Название продукта:</span>{' '}
                            <TruncatedText text={productName} maxLength={13} addStyle={s.value} />
                        </div>
                        <div className={s.row}>
                            <span className={s.key}>GTIN:</span>{' '}
                            <span className={s.value}>{gtin}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.key}>Дата производства:</span>{' '}
                            <span className={s.value}>{consignment.productCreationDate}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.key}>Плановое количество:</span>{' '}
                            <span className={s.value}>{consignment.plannedValue}</span>
                        </div>
                    </div>

                    :
                    <div>
                        <div className={s.row}>
                            <span className={s.key}>Название продукта:</span>
                            <span>{' '}</span>
                            <TruncatedText text={productName} maxLength={13} addStyle={s.value} />
                        </div>
                        <div className={s.row}>
                            <span className={s.key}>GTIN:</span>
                            <span>{' '}</span>
                            <span className={s.value}>{gtin}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.key}>Дата производства:</span>{' '}
                            <span className={s.value}>{!!productionTime && productionTime.toLocaleDateString()}</span>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

