import clsx from "clsx";
import React, { memo, useEffect, useState } from "react";
import g from "../../../../assets/styles/Main.module.scss";
import s from "./Confirm.module.scss";
import LineAPI from "../../../../api/LineAPI";
import { useSelector } from "react-redux";
import { getLineSettings } from "../../../../redux/Settings/Line/lineSettingsSelectors";
import SettingsAPI from "api/SettingsAPI";
import { validate, validateField } from "functions";
import Input from "components/Common/Input/Input";
import { getLineStatus } from "redux/Line/lineSelectors";
import { getTestMode } from "../../../../redux/Settings/Line/lineSettingsSelectors";

const Confirm = ({
  text,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  disabled,
  onClose,
  children,
  isLoading = false,
}) => {
  return (
    <>
      <div className={s.confirm}>
        <p className={s.text}>{text}</p>
        {children}
        <div className={s.controls}>
          {!isLoading && (
            <>
              {agreeText && (
                <button
                  type="button"
                  onClick={onAgree}
                  disabled={disabled}
                  className={clsx(s.btn, s.btnAgree)}
                >
                  {agreeText}
                </button>
              )}
              <button
                type="button"
                onClick={onDisagree}
                className={clsx(s.btn, s.btnDisagree)}
              >
                {disagreeText}
              </button>
            </>
          )}
        </div>
      </div>
      <div className={g.overlay} onClick={isLoading ? null : onClose} />
    </>
  );
};

export const ConfirmLine = memo(({
  text,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  disabled,
  onClose,
  children,
  getBoxNumber,
}) => {
  const [preStartsSettings, setPreStartSettings] = useState(null);
  const lineSettings = useSelector(getLineSettings);
  const [isPartNum, setIsPartNum] = useState(false);
  const [isSerialNumber, setIsSerialNumber] = useState(false);
  const [partNum, setPartNum] = useState("");
  const [boxNum, setBoxNum] = useState(null);
  const [errorToggle, setErrorToggle] = useState(false);

  const lineStatus = useSelector(getLineStatus);


  const onPartNumnChange = (e, name) => {
    const value = e.target.value;
    setPartNum(value);
  };

  const onBoxNumChange = (e, name) => {
    const value = e.target.value;
    setBoxNum(value);
  };

  const isTestMode = useSelector(getTestMode);

  const getInfo = async () => {
    try {
      const res = await LineAPI.getPreStartLineInfo(lineSettings?.id);
      console.log(res);
      if (res?.productName || res?.consignmentNumber) {
        setPreStartSettings(res);
      } else {
        setPreStartSettings(null);
        onDisagree();
      }
    } catch (e) {
      setPreStartSettings(null);
      onDisagree();
    }
  };
  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineSettings?.id]);

  useEffect(() => {
    const getIsPartNum = async () => {
      try {
        const resSerialNumber = await SettingsAPI.getIsSerialNumber();
        // const resSerialNumber = true;
        const res = await SettingsAPI.getIsPartNumber();
        setIsSerialNumber(resSerialNumber);
        setIsPartNum(res);

      } catch (e) {
        console.log(e);
      }
    };
    getIsPartNum();
    setPartNum(localStorage.getItem('partNumber'))
    setBoxNum(localStorage.getItem('boxNumber'))
  }, []);

  const [serialNumberError, setSerialNumberError] = useState(false);
  const [boxNumberError, setBoxNumberError] = useState(false);

  const handleAgree = () => {
    if (!serialNumberError && !boxNumberError) {
      localStorage.setItem('partNumber', partNum);
      localStorage.setItem('boxNumber', boxNum);
      onAgree({ partNum, boxNum: Number(boxNum) });
    }
  }

  return (
    <>
      {preStartsSettings && (
        <div
          className={
            lineStatus == 'PAUSE' || !preStartsSettings.productSelected
              ? s.confirmLine
              : isPartNum
                ? s.confirmProductWpartNum
                : s.confirmProduct
          }
          style={{ height: "auto", paddingBottom: "1.5rem" }}
        >
          <div className={s.modalHeader}>Подтверждение запуска линии</div>
          <div className={s.modalContent}>
            <div className={s.commonContent}>
              {Object.entries(text).map((line) => (
                <p className={s.textRow} key={line.id}>
                  <span className={s.boldText}>{line[0]}</span>
                  <span className={s.text}>{line[1]}</span>
                </p>
              ))}
            </div>

            <div className={s.productContent}>
              {(lineStatus != 'PAUSE' && preStartsSettings.productSelected) && (
                <>
                  <p className={s.boldText}>
                    При закрытии задания будет установлена дата производства:
                  </p>
                  <div className={s.childrenContainer}> {children} </div>
                  <p className={s.productText}>
                    При необходимости Вы можете поменять дату на необходимую Вам.
                  </p>
                  {!isSerialNumber ? (
                    isPartNum && (
                      <div className={s.isPartNumContainer}>
                        <p className={s.boldText}>Номер партии:</p>
                        <input
                          className={s.partuNumInput}
                          value={partNum}
                          // error={lineErrors.has("allowedNumberErrors")}
                          name="signal-more"
                          type="text"
                          onChange={onPartNumnChange}
                          containerStyles={s.containerStyles}
                        />
                      </div>
                    )
                  ) : (
                    <div className={s.isPartNumContainer}>
                      <p className={s.boldText}>Серия:</p>
                      <Input
                        value={partNum}
                        name="serial-number"
                        type="text"
                        onChange={onPartNumnChange}
                        inputStyles={s.partuNumInput}
                        errorText={"Обязательное поле"}
                        validate={true}
                        error={serialNumberError}
                        setError={setSerialNumberError}
                      />
                    </div>
                  )}
                </>
              )}

              {getBoxNumber && (
                <div className={s.isPartNumContainer}>
                  <p className={s.boldText}>Стартовый номер короба:</p>
                  <Input
                    value={boxNum}
                    name="box-number"
                    type="number"
                    onChange={onBoxNumChange}
                    inputStyles={s.partuNumInput}
                    errorText={"Обязательное поле"}
                    validate={true}
                    error={boxNumberError}
                    setError={setBoxNumberError}
                  />
                </div>
              )}

              <p className={s.productText}>
                Количество свободных кодов маркировки <span style={{ color: 'red' }}>{preStartsSettings?.markingCodesRemainder}</span>, если Вы сомневаетесь, что Вам хватит данного количества для выполнения смены, то сообщите административному персоналу для дополнительного заказа.
              </p>
            </div>
          </div>

          {isTestMode ? (
            <p className={s.testModeWarnText}>
              {
                'Внимание: включен тестовый режим. При завершении данное задание будет удалено вместе со всеми агрегатами, отчетами и изменениями статистики". Для запуска задания в стандартном режиме, отключите тестовый режим в общих настройках'
              }
            </p>
          ) : null}
          <div className={s.controls}>
            <button
              type="button"
              onClick={handleAgree}
              disabled={serialNumberError || boxNumberError}
              className={clsx(s.btn, s.btnAgree)}
            >
              {agreeText}
            </button>
            <button
              type="button"
              onClick={onDisagree}
              className={clsx(s.btn, s.btnDisagree)}
            >
              {disagreeText}
            </button>
          </div>
        </div>
      )}
      <div className={g.overlay} onClick={onClose} />
    </>
  );
});
export default Confirm;
