import React, { useState, useRef, useEffect } from 'react';

export const TruncatedText = ({ text, maxLength, addStyle }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkHeight = () => {
      const element = textRef.current;
      if (element) {
        const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
        setIsTruncated(element.scrollHeight > lineHeight * 2);
      }
    };

    checkHeight();
    window.addEventListener('resize', checkHeight);
    return () => window.removeEventListener('resize', checkHeight);
  }, [text]);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div 
      ref={textRef}
      className={`${addStyle} ${!isExpanded ? 'line-clamp-2' : ''}`}
      onClick={isTruncated ? toggleText : undefined}
      style={{
        cursor: isTruncated ? 'pointer' : 'default',
        ...((!isExpanded && isTruncated) && {
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        })
      }}
    >
      {text}
    </div>
  );
};
