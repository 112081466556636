import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { displayModes } from '../../../../constants'
import { setDisplayMode } from '../../../../redux/App/appActionCreators'
import { getSelectedDisplayMode } from '../../../../redux/App/appSelectors'
import s from './ProductionTabs.module.scss'

const tabs = [
    {
        id: 1,
        name: 'Штучная продукция',
        paramName: displayModes.ITEMS
    },
    {
        id: 2,
        name: 'Короба',
        paramName: displayModes.BOXES
    },
    {
        id: 3,
        name: 'Паллеты',
        paramName: displayModes.PALLETS
    },
]

export const ProductionTabs = () => {
    const [tabsToShow, setTabstoShow] = useState(tabs);
    const dispatch = useDispatch();
    const selectedDisplayMode = useSelector(getSelectedDisplayMode);
    const isAggregationIncluded = useSelector(state => state.app.isAggregationIncluded);

    useEffect(() => {
        if (isAggregationIncluded === false) {
            const _tabs = tabs.slice(0, 1);
            setTabstoShow(_tabs);
        }

    }, [isAggregationIncluded])


    const onChangeSelectedTab = (mode) => (e) => {
        e.preventDefault();
        dispatch(setDisplayMode(mode))
    }


    return (
        <div className={s.tabs}>
            {tabsToShow.map(tab => {
                return <button key={tab.id} className={clsx(s.tab, selectedDisplayMode === tab.id && s.selected)}
                    onClick={onChangeSelectedTab(tab.id)}>{tab.name}</button>
            })}
        </div>
    )
}

