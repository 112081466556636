import API from "./APIService";

class LineAPI {
  openLine = (
    id,
    consignmentProductCreationAndPackingTimestamp,
    AlternativeProductCreationDate,
    partNumber,
    boxNumber
  ) =>
    API.post(`/api/productionline/start`, {
      id,
      consignmentProductCreationAndPackingTimestamp,
      AlternativeProductCreationDate,
      partNumber,
      boxNumber,
    });
  stopLine = (lineId) => API.get(`/api/productionline/stop/${lineId}/`);
  pauseLine = (lineId) => API.get(`/api/productionline/pause/${lineId}/`);
  enableEmulationMode = (lineId) =>
    API.get(`/api/productionline/startEmulation/${lineId}/`);
  disableEmulationMode = (lineId) =>
    API.get(`/api/productionline/stopEmulation/${lineId}/`);
  getPreStartLineInfo = (lineId) =>
    API.get(`/api/ProductionLine/starting-info/${lineId}`);
  createAggregate = () => API.post(`/api/productionline/MakeAggregation`);
  getConsignmentById = (consignmentId) =>
    API.get(`/api/Consignment/${consignmentId}`);
  getProductInfo = (lineId) =>
    API.get(`/api/productionline/CurrentProduction/${lineId}/`);
}

export default new LineAPI();
