export let wsServerStart;

export const setServerStartWS = () => {
  return new Promise((resolve, reject) => {
    try {
      const baseWSUrlFromLS = localStorage.getItem("PRODUCTION_LINE_WS");
      const accessData = localStorage.getItem("uplineOperatorData");
      const token = accessData ? JSON.parse(accessData) : "";
      const sessionId = window.sessionStorage.getItem("X-MARK-SESSION-ID");
      const query = token && token.accessToken
        ? `?token=${token.accessToken}&xmarksession=${sessionId}`
        : "";
      const resultUrl = baseWSUrlFromLS || process.env.REACT_APP_WS_URL || "";

      if (!resultUrl) {
        throw new Error("WebSocket URL is not defined");
      }

      wsServerStart = new WebSocket(`${resultUrl}${query}`);
      resolve(wsServerStart);
    } catch (e) {
      console.error("Error in setServerStartWS:", e.message);
      reject(e);
    }
  });
};

export const closeServerStartWS = () => {
  if (wsServerStart) {
    wsServerStart.close();
  }
};
