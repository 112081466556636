import {
  SET_IS_NEW_RELEASE,
  SET_SHOULD_RETRY,
  SET_REALEASE_VERSION,
  RELEASE_INSTALLATION_STATUS,
  RELEASE_UPDATE_ERROR,
  SET_INSTALLED_RELEASE_VERSION,
  SET_INSTALLED_RELEASE_VERSION_INFO,
  SET_IS_MANUAL_MODE,
} from "./appActions";
import { ws_release, startRealeaseWS } from "api/ws-realease";

export const setInstalledRelVersion = (ver) => ({
  type: SET_INSTALLED_RELEASE_VERSION,
  payload: ver,
});

export const setIsManualMode = (mode) => ({
  type: SET_IS_MANUAL_MODE,
  payload: mode,
});

export const setIsNewRelease = (isNewRelease) => ({
  type: SET_IS_NEW_RELEASE,
  payload: isNewRelease,
});

export const setShouldRetry = (shouldRetry) => ({
  type: SET_SHOULD_RETRY,
  payload: shouldRetry,
});

export const setReleaseVersion = (version) => ({
  type: SET_REALEASE_VERSION,
  payload: version,
});

export const setRelVerInfo = (version) => ({
  type: SET_INSTALLED_RELEASE_VERSION_INFO,
  payload: version,
});

export const setReleaseIntallStatus = (status) => ({
  type: RELEASE_INSTALLATION_STATUS,
  payload: status,
});

export const setReleaseError = (error) => ({
  type: RELEASE_UPDATE_ERROR,
  payload: error,
});

export const runReleaseWS = () => async (dispatch, getState) => {
  let retryTimeout;
  const res = await startRealeaseWS();

  ws_release.onopen = async function (e) {
    clearTimeout(retryTimeout);
    console.log(
      "[ws-release open] Соединение для отслежки автообнавления установлено"
    );

    dispatch(setShouldRetry(false));
  };

  ws_release.onclose = async function (event) {
    if (event.wasClean) {
      console.log(
        `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
      );
    } else {
      //  console.log("[close] Release Соединение прервано");
      dispatch(setShouldRetry(true));
    }
  };

  ws_release.onmessage = async function (event) {
    let data = JSON.parse(event?.data);
    if (data?.InstalledReleaseVersion) {
      dispatch(setInstalledRelVersion(data?.InstalledReleaseVersion));
      dispatch(
        setRelVerInfo(
          data?.InstalledReleaseDescription.split("\r\n")
            .map((str) => str.trim())
            .filter((str) => !!str)
        )
      );
    }
    if (data?.ReleaseStatus === 2) {
      dispatch(setIsNewRelease(true));
      dispatch(setReleaseVersion(data?.UpdateToReleaseVersion));
    } else {
      dispatch(setIsNewRelease(false));
    }

    if (data?.ReleaseStatus === 1 || data?.ReleaseStatus === 3) {
      localStorage.setItem("UPDATE_STATUS", "DID_NOT_START");
    }
    if (typeof data?.IsManualStarting === "boolean") {
      dispatch(setIsManualMode(data?.IsManualStarting));
    }

    if (data?.ReleaseInstallationStatus) {
      dispatch(setReleaseIntallStatus(data?.ReleaseInstallationStatus));
      localStorage.setItem(
        "ReleaseInstallationStatus",
        data?.ReleaseInstallationStatus
      );
    }
    // else dispatch(setIsNewRelease(false));
  };

  ws_release.addEventListener("close", function (event) {
    // console.log("disconnected");
    dispatch(setShouldRetry(true));
  });

  //   ws_release.on("close", function () {
  //     console.log("closed abruptly");
  //   });

  ws_release.onerror = async function (error) {
    // console.log(`[error] ${error.message}`);
    dispatch(setShouldRetry(true));
    dispatch(setReleaseIntallStatus("error"));
  };
};
