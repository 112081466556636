import clsx from 'clsx'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import s from './Input.module.scss';
import g from './../../../assets/styles/Main.module.scss';
import { validateField } from 'functions';
import { values } from 'office-ui-fabric-react';


const Input = memo(({ setError, validate = false, title, name, value, subtitle, onChange, type, error, disabled, containerStyles, inputStyles, min, max, isDefault = true, errorText }) => {

    const [localError, setLocalError] = useState(false);

    useEffect(() => {
        if (validate) {
            const isValid = validateField(name, value);
            setLocalError(!isValid);
            setError(!isValid);
        }
    }, [validate, name, value, setError]);
    return (
        <div className={clsx({
            [s.input]: isDefault,
            [`${s.input}${name}`]: true,
            [containerStyles]: true
        })}>
            {!subtitle && isDefault ? <span className={s.title}>{title}</span> :
                isDefault ? <span className={s.subtitle}>{subtitle}</span> : ''}
            <input type={type} disabled={disabled} name={name} value={value} placeholder={title}
                className={clsx({
                    [s.inputInput]: isDefault,
                    [g.messageError]: localError,
                    [inputStyles]: true
                })}
                onChange={onChange}
                min={min} max={max}
            />
            {localError && (
                <span className={g.errorMessageText}>{errorText}</span>
            )}
        </div>
    )
}
)

export default Input;
