import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import g from "../../assets/styles/Main.module.scss";
import s from "./Root.module.scss";
import { getProductById } from "../../functions";
import {
  openSettings,
  setErrorText,
  getAggregationIncluded,
  getTestModeIncluded,
} from "../../redux/App/appActionCreators";
import {
  getErrorText,
  getIsOpenedSettings,
} from "../../redux/App/appSelectors";
import { getAccessLevel } from "../../redux/Auth/authSelectors";
import {
  pauseLine,
  resetLine,
  runLine,
  stopLine,
  setProductionTime,
  getSelectedLine,
} from "../../redux/Line/lineActionCreators";
import {
  getLineStatus,
  getLoadingControl,
  getProduction,
  getProductionStats,
} from "../../redux/Line/lineSelectors";
import {
  getConsignmentId,
  getIsConsignment,
  getLineSettings,
  getTestMode,
} from "../../redux/Settings/Line/lineSettingsSelectors";
import {
  getNumberPackagesInAggregatesLv1,
  getNoFixedNumberPackagesInAggregates,
} from "../../redux/Settings/Aggregation/aggregationSettingsSelectors";
import {
  resetSettings,
  saveSettings,
} from "../../redux/Settings/settingsActionCreators";
import { Alert } from "../Common/Alerts/Alert/Alert";
import Confirm, { ConfirmLine } from "../Common/Alerts/Confirm/Confirm";
import Control from "../Control/Control";
import Help from "../HelpPage/Help";
import Settings from "../SettingsPage/Settings";
import { AggregationView } from "./components/AggregationView";
import { InfoTable } from "./components/InfoTable/InfoTable";
import { ProductionTabs } from "./components/ProductionTabs/ProductionTabs";
import ResultsTable from "./components/ResultsTable/ResultsTable";
import SliderPopup from "./components/SliderPopup/SliderPopup";
import DatePicker, { registerLocale } from "react-datepicker";
import CalendarIcon from "components/Common/Alerts/Confirm/Calendar";
import ru from "date-fns/locale/ru";
import { getTime } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.scss";
import RunningLineSettings from "./components/CurrentSettingsModal";
import { printingModeList } from "../../constants";
import { getShouldShowDemo } from "redux/Settings/BaseSettings/baseSettingsActionCreators";
import VersionInfo from "./version-info/VersiionInfo";
import SettingsAPI from "api/SettingsAPI";

registerLocale("ru", ru);

let controls = [
  {
    name: "RUN",
    title: "Запуск линии",
    icon: require("../../assets/img/icons/run.png"),
  },
  {
    name: "PAUSE",
    title: "Пауза/пуско-наладка",
    icon: require("../../assets/img/icons/pause.png"),
  },
  {
    name: "STOP",
    title: "Остановка линии",
    icon: require("../../assets/img/icons/stop.png"),
  },
  {
    name: "settings",
    title: "Настройки",
    icon: require("../../assets/img/icons/settings.png"),
  },
];

const Root = ({ isOpenedHelp, setIsOpenedHelp }) => {
  const dispatch = useDispatch();
  const lineStatus = useSelector(getLineStatus);
  const isOpenedSettings = useSelector(getIsOpenedSettings);
  const loadingControl = useSelector(getLoadingControl);
  const { products, boxes, pallets } = useSelector(getProductionStats);
  const accessLevel = useSelector(getAccessLevel);
  const production = useSelector(getProduction);
  const numberPackagesInAggregates = useSelector(
    getNumberPackagesInAggregatesLv1
  );
  const noFixedNumberPackagesInAggregates = useSelector(
    getNoFixedNumberPackagesInAggregates
  );
  const lineSettings = useSelector(getLineSettings);
  const isConsignment = useSelector(getIsConsignment);
  const errorText = useSelector(getErrorText);
  const consignmentId = useSelector(getConsignmentId);
  const resetTableFuncs = useRef(null);
  const [showSliderPopup, setShowSliderPopup] = useState(false);
  const [itemPhotos, setItemPhotos] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [pressedControl, setPressedControl] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const printingMode = useSelector((state) => state.settings.printing.type);
  const [showSimpleAlert, setSimpleALert] = useState(false);
  const [lineConfirmText, setLineConfirmText] = useState(null);
  const isTestMode = useSelector(getTestMode);
  const [getBoxNumber, setGetBoxNumber] = useState(false);

  const handleSettingsButton = (isOpen, lineSt) => {
    if (lineSt === "RUN") {
      setSimpleALert(true);
    } else {
      dispatch(openSettings(isOpen));
    }
  };

  useEffect(() => {
    dispatch(getShouldShowDemo());
    const getBoxNumberFunc = async () => {
      const res = await SettingsAPI.getBoxNumber();
      
      setGetBoxNumber(res);
    }
    getBoxNumberFunc();
    dispatch(getSelectedLine());
  }, []);

  useEffect(() => {
    dispatch(getAggregationIncluded());
    dispatch(setProductionTime(startDate));
    dispatch(getTestModeIncluded());
  }, [dispatch, startDate]);

  useEffect(() => {
    if (pressedControl) {
      _setConfirmText();
      if (
        (isConsignment && !consignmentId) ||
        (!isConsignment && !production?.selectedProductId)
      ) {
        setShowAlert(true);
      } else {
        setShowConfirmation(true);
      }
    } else {
      setShowConfirmation(false);
      setShowAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedControl]);

  useEffect(() => {
    if (errorText) {
      setShowErrorAlert(true);
    } else {
      setShowErrorAlert(false);
    }
  }, [errorText]);

  const showPhotos = async (photos) => {
    await setItemPhotos(photos);
    await setShowSliderPopup(true);
  };

  const resetData = useCallback(async () => {
    if (lineStatus !== "STOP") {
      dispatch(resetLine());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineStatus]);

  const _stopLine = async () => {
    if (lineStatus === "RUN" || lineStatus === "PAUSE") {
      await dispatch(stopLine());
      if (products || boxes || pallets) {
        await resetData();
      }
    }
  };
  const _setConfirmText = () => {
    if (!lineSettings) {
      setConfirmationText(`Выберите линию`);
    } else {
      if (
        (isConsignment && !consignmentId) ||
        (!isConsignment && !production?.selectedProductId)
      ) {
        setConfirmationText(`Выберите задание или продукт в настройках`);
      } else {
        if (pressedControl === "RUN") {
          setLineConfirmText({
            "Продукт:":
              `${getProductById(
                production?.selectedProductId,
                production?.products
              )}` || "",
            "Способ нанесения КМ:": `${printingModeList[printingMode]}`,
            "Количество продуктов в коробе:": noFixedNumberPackagesInAggregates
              ? "Переменное"
              : numberPackagesInAggregates,
          });
        }

        if (pressedControl === "PAUSE") {
          setConfirmationText(`Подтверждение операции — Приостановка линии`);
        }

        if (pressedControl === "STOP") {
          if (isConsignment) {
            setConfirmationText(`Внимание! При остановкее линии сменное задание будет завершено.
                                    Перед следующим запуском нужно будет выбрать новое задание и проверить продукт.`);
          } else {
            setConfirmationText(`Подтверждение операции — Остановка линии`);
          }
        }
      }
    }
  };

  const changeLineStatus = () => (status) => {
    switch (status) {
      case "run":
        dispatch(
          runLine({ consignmentProductCreationAndPackingTimestamp: Date.now() })
        );
        break;
      case "pause":
        dispatch(pauseLine());
        break;
      case "openSettings":
        dispatch(openSettings());
        break;
      default:
        dispatch(stopLine());
    }
  };

  return (
    <div className={g.main}>
      {showErrorAlert && (
        <Alert
          closeText="Закрыть"
          text={errorText}
          onClose={() => {
            setShowErrorAlert(false);
            dispatch(setErrorText(""));
          }}
        />
      )}
      {showSimpleAlert && (
        <RunningLineSettings onClose={() => setSimpleALert(false)} />
      )}
      {showAlert && (
        <Alert
          text={confirmationText}
          onClose={() => setPressedControl(null)}
          closeText={"Закрыть"}
        />
      )}
      {showConfirmation && pressedControl !== "RUN" && (
        <Confirm
          text={confirmationText}
          agreeText={`${pressedControl === "STOP" && isConsignment
              ? "Завершить задание"
              : "Подтвердить"
            }`}
          disagreeText="Отменить"
          onDisagree={() => setPressedControl(null)}
          onAgree={() => {
            if (pressedControl === "PAUSE") {
              dispatch(pauseLine());
            }
            if (pressedControl === "STOP") {
              _stopLine();
            }
            setPressedControl(null);
          }}
        />
      )}
      {showConfirmation && pressedControl === "RUN" && (
        <ConfirmLine
          lineId={lineSettings?.id}
          text={lineConfirmText}
          agreeText={`${pressedControl === "STOP" && isConsignment
              ? "Завершить задание"
              : "Подтвердить"
            }`}
          disagreeText="Отменить"
          onDisagree={() => setPressedControl(null)}
          onAgree={(inputValueData) => {
            if (pressedControl === "RUN") {
              dispatch(setProductionTime(startDate));
              dispatch(
                runLine({
                  consignmentProductCreationAndPackingTimestamp:
                    getTime(startDate),
                    partNumber: inputValueData.partNum,
                    boxNumber: inputValueData.boxNum,
                })
              );
            }
            setPressedControl(null);
          }}
          getBoxNumber={getBoxNumber}
        >
          <DatePicker
            selected={startDate}
            className={s.datePicker}
            wrapperClassName={s.datePickerWrapper}
            onChange={(date) => {
              setStartDate(date);
            }}
            dateFormat="dd.MM.yyyy"
            locale={"ru"}
          />
          <CalendarIcon />
        </ConfirmLine>
      )}
      {showSliderPopup && (
        <SliderPopup
          images={itemPhotos}
          onClose={() => setShowSliderPopup(false)}
        />
      )}
      {!isOpenedSettings && (
        <div className={g.wrapper}>
          <div className={g.content}>
            <aside className={g.controls}>
              {controls.map((item, i) => {
                return (
                  <Control
                    key={i}
                    resetData={resetData}
                    lineStatus={lineStatus}
                    setOperationName={setPressedControl}
                    icon={item.icon}
                    text={item.title}
                    name={item.name}
                    run={changeLineStatus("run")}
                    pause={changeLineStatus("pause")}
                    stop={changeLineStatus("stop")}
                    accessLevel={accessLevel}
                    loadingControl={loadingControl}
                    toggleWindow={handleSettingsButton}
                    active={lineStatus === item.name}
                  />
                );
              })}
              <VersionInfo />
            </aside>
            <div className={g.info}>
              <InfoTable isConsignment={isConsignment} />

              <AggregationView />
              <ProductionTabs />
              <ResultsTable
                showPhotos={showPhotos}
                resetTableFuncs={resetTableFuncs}
              />
            </div>
          </div>
        </div>
      )}
      {isOpenedSettings && (
        <Settings
          openSettings={openSettings}
          resetSettings={resetSettings}
          saveSettings={saveSettings}
          setShowErrorAlert={setShowErrorAlert}
        />
      )}
      {isOpenedHelp && <Help setIsOpenedHelp={setIsOpenedHelp} />}
    </div>
  );
};

export default Root;
