import React, { useEffect, useState } from "react";
import s from "./Select.module.scss";
import FancySelect from "react-select";

const Select = ({
  title,
  name,
  options,
  selectedValue,
  onChange,
  disabled,
}) => {
  return (
    <div className={s.select}>
      <span className={s.title}>{title}</span>
      <div className={s.container}>
        <select
          name={name}
          id=""
          size={1}
          disabled={disabled}
          className={s.selectInput}
          value={selectedValue}
          onChange={(e) => onChange(e.target.value)}
        >
          {options.map((item) => {
            return (
              <option key={item.id} value={item.id} className={s.option}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export const SelectComponent = ({
  title,
  name,
  options,
  selectedValue,
  onChange,
  disabled,
}) => {
  const [menuHeight, setMenuHeight] = useState(400);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1024px)');
    
    const handleMediaChange = (e) => {
      setMenuHeight(e.matches ? 200 : 400); // 200px для мобильных, 400px для десктопа
    };
    
    handleMediaChange(mediaQuery);
    
    mediaQuery.addEventListener('change', handleMediaChange);
    
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  const options2 = options.map((option) => {
    return { value: option.id, label: option.name };
  });

  const customStyles = {
    indicatorSeparator: () => {},
    control: () => ({
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      border: "1px solid #8A8886",
      backgroundColor: "transparent",
      boxSizing: "border-box",
      borderRadius: "2px",
      fontSize: "1.125rem",
      lineHeight: "1.375rem",
      fontWeight: "500",
      outline: "none",
      padding: "0 0.9375rem",
      zIndex: "6",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 102,
    }),
  };

  if (selectedValue === null) selectedValue = 0;

  return (
    <div className={s.select}>
      <span className={s.title}>{title}</span>
      <div className={s.container}>
        <FancySelect
          name={name}
          options={options2}
          maxMenuHeight={menuHeight}
          isDisabled={disabled}
          value={
            typeof selectedValue === "number"
              ? options2.filter((el) => el.value === +selectedValue)[0]
              : options2.filter((el) => el.value === selectedValue)[0]
          }
          onChange={(reading) => onChange(reading.value)}
          styles={customStyles}
        />
      </div>
    </div>
  );
};
export default Select;
